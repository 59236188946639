import { Injectable } from '@angular/core';
import { AuthService } from 'adk-portal-shared';
import { UserGroups } from '../models/user.model';
import { CardDropDownItem } from '../models/card.model';

@Injectable({
  providedIn: 'root'
})
export class UserGroupService {

  constructor(private authService: AuthService) { }

  getUserGroups(): UserGroups[] {
    return this.authService.user.cognitoGroups;
  }

  isUserInGroup(group: string): boolean {
    const userGroups = this.getUserGroups();
    return userGroups?.some(userGroup => userGroup === group) || false;
  }

  hasSuperAdminPrivilege(): boolean {
    return this.isUserInGroup(UserGroups.SUPER_ADMIN) || this.isUserInGroup(UserGroups.OWNER);
  }

  getPortalGroupDropdown(): CardDropDownItem[] {
    return [{
      label: 'Client Admin',
      value: UserGroups.CLIENT_ADMIN
    }, {
      label: 'Super Admin',
      value: UserGroups.SUPER_ADMIN
    }];
  }
}
