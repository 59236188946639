import { Injectable } from '@angular/core';
import { Message, PrimeIcons } from 'primeng/api';
import { Subject } from 'rxjs';

const STANDARD_MESSAGE_LIFE = 8000;
type Severities = 'success' | 'info' | 'warn' | 'error';

@Injectable({
  providedIn: 'root',
})
export class NotificationsService {
  messageNotificationChange: Subject<Message[] | object> = new Subject<Message[] | object>();

  private message: Message[] = [];

  messageNotifyNow() {
    this.messageNotificationChange.next(this.message);
    this.message = [];
  }

  notificationClear() {
    this.message = [];
    this.messageNotifyNow();
  }

  messageNotify(severity: Severities, detail: string) {
    this.messageNotificationChange.next({ severity, detail, icon: this.getIcon(severity), life: STANDARD_MESSAGE_LIFE });
  }

  addNotification(severity: Severities, detail: string) {
    this.message.push({ severity, detail, icon: this.getIcon(severity), life: STANDARD_MESSAGE_LIFE });
  }

  getIcon(severity: Severities) {
    switch (severity) {
      case 'success':
        return PrimeIcons.CHECK;
      case 'info':
        return PrimeIcons.INFO;
      case 'warn':
        return PrimeIcons.EXCLAMATION_TRIANGLE;
      case 'error':
        return PrimeIcons.EXCLAMATION_TRIANGLE;
      default:
        return PrimeIcons.INFO;
    }
  }
}
