/* eslint-disable @typescript-eslint/no-explicit-any */
import { LogLevel as AppLogLevel, Environment } from 'adk-portal-shared';
import { LogLevel as OIDCLogLevel } from 'angular-auth-oidc-client';

declare let require: any;

export const environment: Environment = {
  production: true,
  oidcConfig: {
    config: {
      authority: 'https://cognito-idp.us-east-1.amazonaws.com/us-east-1_GzTbSpXD3/',
      redirectUrl: window.location.origin + '/callback-oidc',
      postLogoutRedirectUri: window.location.origin,
      clientId: '4p2bk8sn3si9o2fhhu4980er8',
      scope: 'openid phone email profile',
      postLoginRoute: '/admin',
      responseType: 'code',
      silentRenew: false,
      useRefreshToken: true,
      allowUnsafeReuseRefreshToken: true,
      logLevel: OIDCLogLevel.Debug,
    },
  },
  logLevel: AppLogLevel.INFO,
  appVersion: require('../../package.json').version,
  baseUrl: 'https://admin.dev.developer.accesso.com',
  adkPortalApiBaseUrl: 'https://api.dev.developer.accesso.com',
  defaultLandingRoute: '/admin',
  s3BucketBaseUrl: 'https://docs.dev.developer.accesso.com',
  googleAnalyticsId: 'G-JLP02GWXQR',
  originatingApp: 'admin',
};
