import { Injectable } from '@angular/core';
import { permissions, EntityType } from '../models/permission.model';
import { UserGroupService } from './user-group.service';


@Injectable({
    providedIn: 'root',
})
export class PermissionService {
      
 constructor(private userGroups: UserGroupService) {}

 hasPermissions(action: 'view' | 'edit', entity: EntityType): boolean {
   const roles = this.userGroups.getUserGroups();
   for (const role of roles) {
      const rolePermissions = permissions[role];
    
      if (!rolePermissions) {
        throw new Error(`Role ${role} does not exist.`);
      }
    
      if (action === 'view' && rolePermissions.canView.includes(entity)) {
        return true; 
      }
    
      if (action === 'edit' && rolePermissions.canEdit.includes(entity)) {
        return true;
      }
    }
    return false; 
 }
}