import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ErrorPageComponent, OidcCallbackComponent, PostLoginGuard, TermsAndConditionComponent, TermsAndConditionGuard } from 'adk-portal-shared';
import { AccessDeniedComponent } from './components/access-denied/access-denied.component';
import { AdminComponent } from './components/admin/admin.component';
import { LandingComponent } from './components/layout/landing/landing.component';
import { AppPermissionGuard } from './shared/guards/app-permission.guard';
import { RoleAccessGuard } from './shared/guards/role-access.guard';
import { UserGroups } from './shared/models/user.model';

export const routes: Routes = [
  {
    path: 'callback-oidc',
    component: OidcCallbackComponent,
  },
  {
    path: 'terms-and-condition',
    component: TermsAndConditionComponent,
  },
  {
    path: 'access-denied',
    component: AccessDeniedComponent,
  },
  {
    path: '',
    component: LandingComponent,
  },
  {
    path: 'admin',
    component: AdminComponent,
    canActivate: [PostLoginGuard, TermsAndConditionGuard, AppPermissionGuard],
    children: [
      {
        path: 'users',
        canActivate: [RoleAccessGuard],
        data: { allowedRoles: [UserGroups.CLIENT_ADMIN] },
        loadChildren: () => import('./components/user/user.routes').then((m) => m.userRoutes),
      },
      {
        path: 'clients',
        canActivate: [RoleAccessGuard],
        loadChildren: () => import('./components/client/client.routes').then((m) => m.clientRoutes),
        data: { allowedRoles: [UserGroups.CLIENT_ADMIN] },
      },
      {
        path: 'products',
        canActivate: [RoleAccessGuard],
        data: { allowedRoles: [UserGroups.PRODUCT_ADMIN] },
        loadChildren: () => import('./components/product/product.routes').then((m) => m.productRoutes),
      },
      {
        path: 'files',
        canActivate: [RoleAccessGuard],
        data: { allowedRoles: [UserGroups.PRODUCT_ADMIN] },
        loadChildren: () => import('./components/file/files.route').then((m) => m.filesRoutes),
      },
    ],
  },
  {
    path: 'error',
    component: ErrorPageComponent,
    data: {
      type: 404,
      title: 'Page Not Found',
      desc: "The page you were looking for doesn't exist.",
    },
  },
  {
    path: 'error/:type',
    component: ErrorPageComponent,
  },
  { path: '**', redirectTo: 'error', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
