import { UserGroups, UserRoleEnum } from './user.model';

export const Entity = {
  ...UserRoleEnum,
  CLIENTS: 'CLIENTS',
  USERS: 'USERS',
  PRODUCTS: 'PRODUCTS',
} as const;

type Entity = (typeof Entity)[keyof typeof Entity];

export interface Permissions {
  canView: EntityType[];
  canEdit: EntityType[];
}

export type EntityType = (typeof Entity)[keyof typeof Entity];

export const permissions: Record<UserGroups, Permissions> = {
  [UserGroups.OWNER]: {
    canView: [Entity.CLIENTS, Entity.USERS, Entity.SUPER_ADMIN, Entity.CLIENT_ADMIN, Entity.DEVELOPER, Entity.PRODUCTS, Entity.PRODUCT_ADMIN],
    canEdit: [Entity.CLIENTS, Entity.USERS, Entity.SUPER_ADMIN, Entity.CLIENT_ADMIN, Entity.DEVELOPER, Entity.PRODUCTS, Entity.PRODUCT_ADMIN],
  },
  [UserGroups.SUPER_ADMIN]: {
    canView: [Entity.CLIENTS, Entity.USERS, Entity.SUPER_ADMIN, Entity.CLIENT_ADMIN, Entity.DEVELOPER, Entity.PRODUCTS, Entity.PRODUCT_ADMIN],
    canEdit: [Entity.CLIENTS, Entity.USERS, Entity.CLIENT_ADMIN, Entity.DEVELOPER, Entity.PRODUCTS, Entity.PRODUCT_ADMIN],
  },
  [UserGroups.CLIENT_ADMIN]: {
    canView: [Entity.CLIENTS, Entity.USERS, Entity.CLIENT_ADMIN, Entity.DEVELOPER],
    canEdit: [Entity.DEVELOPER],
  },
  [UserGroups.PRODUCT_ADMIN]: {
    canView: [Entity.PRODUCTS],
    canEdit: [Entity.PRODUCTS],
  },
  [UserGroups.DEVELOPER]: {
    canView: [],
    canEdit: [],
  },
};
