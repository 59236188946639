import { Component, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import {
  FooterComponent,
  HomeComponent,
  NavbarComponent,
  AuthService,
  TermsAndConditionService,
} from 'adk-portal-shared';
import { NgIf } from '@angular/common';
import { SidebarComponent } from '../sidebar/sidebar.component';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss'],
  standalone: true,
  imports: [
    SidebarComponent,
    NavbarComponent,
    NgIf,
    RouterOutlet,
    FooterComponent,
    HomeComponent,
  ],
})
export class LandingComponent implements OnInit {
  isUserLoggedIn = false;
  acceptedLatestTerms = false;
  constructor(
    private authService: AuthService,
    private termsAndConditionService: TermsAndConditionService
  ) {}

  ngOnInit(): void {
    this.isUserLoggedIn = this.authService.isLoggedIn;
    this.acceptedLatestTerms =
      this.termsAndConditionService.acceptedLatestTerms;
  }
}
