import { NgIf } from '@angular/common';
import { Component } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { PanelMenuModule } from 'primeng/panelmenu';
import { PermissionService } from '../../../shared/service/permission.service';
import { UserGroupService } from '../../../shared/service/user-group.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  standalone: true,
  imports: [NgIf, PanelMenuModule],
})
export class SidebarComponent {
  constructor(
    private userGroupService: UserGroupService,
    private permissionService: PermissionService
  ) {}

  isSideBarOpen = true;

  items: MenuItem[] = [
    {
      label: 'Access Management',
      icon: 'pi pi-fw pi-user',
      expanded: true,
      visible: this.permissionService.hasPermissions('view', 'USERS') || this.permissionService.hasPermissions('view', 'CLIENTS'),
      items: [
        { label: 'Users', routerLink: '/admin/users' },
        {
          label: this.userGroupService.hasSuperAdminPrivilege() ? 'Clients' : 'Client',
          routerLink: '/admin/clients',
          visible: this.permissionService.hasPermissions('view', 'CLIENTS'),
        },
      ],
    },
    {
      label: 'Product Management',
      icon: 'pi pi-fw pi-sitemap',
      expanded: true,
      visible: this.permissionService.hasPermissions('view', 'PRODUCTS'),
      items: [
        { label: 'Products', routerLink: '/admin/products' },
        { label: 'Files', routerLink: '/admin/files' },
      ],
    },
  ];

  toggleSidebar() {
    this.isSideBarOpen = !this.isSideBarOpen;
  }
}
