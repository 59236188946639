import { Component, OnInit, OnDestroy } from '@angular/core';
import { Message } from 'primeng/api';
import { MessagesModule } from 'primeng/messages';
import { NotificationsService } from '../../shared/service/notification.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-notifications',
  standalone: true,
  imports: [MessagesModule],
  templateUrl: './notifications.component.html',
  styleUrl: './notifications.component.scss'
})
export class NotificationsComponent implements OnInit, OnDestroy {
  message: Message[] = [];
  messageSubscription: Subscription = new Subscription();

  constructor(private notificationsService: NotificationsService) { }

  ngOnInit() {
    this.subscribeToMessageNotifications();
  }

  subscribeToMessageNotifications() {
    this.messageSubscription = this.notificationsService.messageNotificationChange
      .subscribe(( notification: Message[] | object)  => {
        this.message = [];
        if(notification !== undefined && !(notification instanceof Array)) {
            this.message.push(notification);
        }
        if ( notification !== undefined && notification instanceof Array) {
          notification.forEach((element: Message) => {
            this.message.push(element);
          });
        }
      });
  }

  ngOnDestroy() {
    this.messageSubscription.unsubscribe();
  }
}