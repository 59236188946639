import { Client } from './client.model';
import { PaginatedListData } from './page.model';

export interface User {
  id: string;
  name: string;
  email: string;
  avatar: string;
  isAuthenticated: boolean;
  customerId?: string;
  userType?: string;
  customAttributes?: Record<string, string>;
  idToken?: string;
}

export enum UserGroups {
  DEVELOPER = 'developer',
  CLIENT_ADMIN = 'client-admin',
  SUPER_ADMIN = 'super-admin',
  OWNER = 'owner',
  PRODUCT_ADMIN = 'product-admin',
}

export interface CognitoUser {
  idpUserId: string;
  name: string;
  email: string;
  locale: string;
  pictureUrl: string;
  zoneInfo: string;
  clientId: string;
  editStatus: string;
  lastUpdatedDate: string;
  phoneNumber: string;
  createdDate: string;
}

// eslint-disable-next-line @typescript-eslint/no-empty-object-type
export interface UserListData extends PaginatedListData<CognitoUser> {
  // Future properties for UserListData can be added here
}

export interface LastUpdatedBy {
  idpUserId: string;
  name: string;
  email: string;
  clientId: string;
  client?: Client;
  editStatus: string;
}

export interface UserGroupsDropDown {
  label: string;
  value: (typeof UserGroups)[keyof typeof UserGroups];
}

export interface CreatedBy {
  idpUserId: string;
  name: string;
  email: string;
  clientId: string;
  client?: Client;
  editStatus: string;
}

export interface Terms {
  version: string;
  url: string;
  format: string;
  effectiveDate: string;
  createdDate: string;
  createdBy: CreatedBy;
  lastUpdatedDate: string;
  lastUpdatedBy: LastUpdatedBy;
  agreementDatetime: string;
}

export interface UserTermAgreement {
  user: {
    idpUserId: string;
    name: string;
    email: string;
    clientId: string;
    client: Client;
  };
  terms: Terms;
  agreementDatetime: string;
}

export interface DevPortalUser extends CognitoUser {
  lastUpdatedBy?: LastUpdatedBy;
  createdBy?: CreatedBy;
  client?: Client;
  phone: Phone;
  roles: UserRoleEnum[];
  userTermsAgreements?: UserTermAgreement[];
}

export interface Phone {
  countryCode: string;
  dialCode: string;
  nationalNumber: string;
}

export interface UserRequest {
  idpUserId?: string;
  email: string;
  name: string;
  phone: Phone;
  group: string;
  locale: string;
  zoneInfo: string;
  clientId: string;
  pictureUrl: string;
}

export enum UserActions {
  USER_STATUS = 'USER_STATUS',
  USER_DELETE = 'USER_DELETE',
  USER_INVITE = 'USER_INVITE',
}

export enum EditStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  DELETED = 'DELETED',
}

export enum UserRoleEnum {
  DEVELOPER = 'DEVELOPER',
  CLIENT_ADMIN = 'CLIENT_ADMIN',
  SUPER_ADMIN = 'SUPER_ADMIN',
  OWNER = 'OWNER',
  PRODUCT_ADMIN = 'PRODUCT_ADMIN',
}

export class UserRole {
  constructor(
    public readonly id: number,
    public readonly group: string,
    public readonly role: UserRoleEnum,
    public readonly isAdmin: boolean
  ) {}

  static readonly DEVELOPER = new UserRole(1, 'developer', UserRoleEnum.DEVELOPER, false);
  static readonly CLIENT_ADMIN = new UserRole(2, 'client-admin', UserRoleEnum.CLIENT_ADMIN, true);
  static readonly SUPER_ADMIN = new UserRole(3, 'super-admin', UserRoleEnum.SUPER_ADMIN, true);
  static readonly OWNER = new UserRole(4, 'owner', UserRoleEnum.OWNER, true);
  static readonly PRODUCT_ADMIN = new UserRole(5, 'product-admin', UserRoleEnum.PRODUCT_ADMIN, true);

  static values(): UserRole[] {
    return [UserRole.DEVELOPER, UserRole.CLIENT_ADMIN, UserRole.SUPER_ADMIN, UserRole.OWNER];
  }

  static isSuperAdmin(): UserRole[] {
    return [UserRole.SUPER_ADMIN, UserRole.OWNER];
  }

  static isAdmin(): UserRole[] {
    return [UserRole.CLIENT_ADMIN, UserRole.SUPER_ADMIN, UserRole.OWNER];
  }
}

export const NON_ROOT_CLIENTS_ALLOWED_ROLES = [UserRole.CLIENT_ADMIN.group, UserRole.DEVELOPER.group];
