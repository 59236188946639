//TODO - fix lint issue
/* eslint-disable @typescript-eslint/no-explicit-any, @typescript-eslint/no-unused-vars */
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from 'adk-portal-shared';
import { jwtDecode } from 'jwt-decode';
import { Observable, of } from 'rxjs';
import { UserGroups } from '../models/user.model';

@Injectable({
  providedIn: 'root',
})
export class AppPermissionGuard {
  constructor(
    private router: Router,
    private authService: AuthService
  ) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    if (this.authService.isLoggedIn) {
      if (this.authService.user.idToken) {
        const user: any = jwtDecode(this.authService.user.idToken);
        const userGroups = user?.['cognito:groups'] as string[] | undefined;
        const isAdmin = userGroups?.some(
          (group) => group === UserGroups.CLIENT_ADMIN || group === UserGroups.SUPER_ADMIN || group === UserGroups.OWNER || group === UserGroups.PRODUCT_ADMIN
        );
        if (isAdmin) {
          return of(true);
        }
      }
    }
    this.router.navigate(['/access-denied']);
    return of(false);
  }
}
