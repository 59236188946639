import { Component, OnInit } from '@angular/core';
import { Router, RouterOutlet } from '@angular/router';
import { UserGroups } from '../../shared/models/user.model';
import { UserGroupService } from '../../shared/service/user-group.service';

@Component({
  selector: 'app-admin',
  standalone: true,
  imports: [RouterOutlet],
  template: '<router-outlet></router-outlet>',
})
export class AdminComponent implements OnInit {
  constructor(
    private router: Router,
    private userGroupService: UserGroupService
  ) {}

  ngOnInit(): void {
    this.navigateToAppropriateRoute();
  }

  private navigateToAppropriateRoute(): void {
    const currentUrl = this.router.url;

    // Check it is the landing route from cognito
    if (currentUrl === '/admin') {
      this.redirectBasedOnUserGroups();
    } else {
      // for page refresh, navigate to the current route
      this.router.navigate([currentUrl]);
    }
  }

  private redirectBasedOnUserGroups(): void {
    if (this.hasGroupAccess([UserGroups.CLIENT_ADMIN, UserGroups.SUPER_ADMIN, UserGroups.DEVELOPER])) {
      this.router.navigate(['/admin/users']);
    } else if (this.hasGroupAccess([UserGroups.PRODUCT_ADMIN])) {
      this.router.navigate(['/admin/products']);
    } else {
      this.router.navigate(['/admin/access-denied']);
    }
  }

  private hasGroupAccess(groups: UserGroups[]): boolean {
    const userGroups = this.userGroupService.getUserGroups();
    return groups.some((group) => userGroups.includes(group));
  }
}
