import { DatePipe } from '@angular/common';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { enableProdMode, importProvidersFrom } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { bootstrapApplication, BrowserModule } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { APP_ENVIRONMENT, EnvironmentService, TokenInterceptor } from 'adk-portal-shared';
import { provideAuth } from 'angular-auth-oidc-client';
import { provideMarkdown } from 'ngx-markdown';
import { DialogService } from 'primeng/dynamicdialog';
import { AppComponent } from './app/app.component';
import { AppRoutingModule } from './app/app.routes';
import { environment } from './environments/environment';

const appEnviornment = new EnvironmentService(environment);

if (environment.production) {
  enableProdMode();
}

bootstrapApplication(AppComponent, {
  providers: [
    provideAuth(environment.oidcConfig),
    importProvidersFrom(BrowserModule, AppRoutingModule, FormsModule),
    DatePipe,
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    { provide: APP_ENVIRONMENT, useValue: appEnviornment },
    provideAnimations(),
    provideHttpClient(withInterceptorsFromDi()),
    provideMarkdown(),
    DialogService,
  ],
}).catch((err) => console.error(err));
