import { Component, OnDestroy, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { AuthService, FooterComponent, GoogleAnalyticsService, NavbarComponent, SessionService, TermsAndConditionService } from 'adk-portal-shared';
import { Subscription } from 'rxjs';
import { SidebarComponent } from './components/layout/sidebar/sidebar.component';
import { NotificationsComponent } from './components/notifications/notifications.component';
import { UserRoleDirective } from './shared/directives/user-role.directive';
import { UserGroups } from './shared/models/user.model';
import { NotificationsService } from './shared/service/notification.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  providers: [NotificationsService],
  imports: [RouterOutlet, NavbarComponent, FooterComponent, SidebarComponent, UserRoleDirective, NotificationsComponent],
})
export class AppComponent implements OnInit, OnDestroy {
  isUserLoggedIn = false;
  isTermsAccepted = false;
  allowedRoles = [UserGroups.OWNER, UserGroups.SUPER_ADMIN, UserGroups.PRODUCT_ADMIN, UserGroups.CLIENT_ADMIN];
  private loggedInSubscription: Subscription | undefined;
  private termsAcceptedSubscription: Subscription | undefined;
  constructor(
    private authService: AuthService,
    private termsAndConditionService: TermsAndConditionService,
    private sessionService: SessionService,
    private googleAnalyticsService: GoogleAnalyticsService
  ) {}
  ngOnInit() {
    this.isUserLoggedIn = this.authService.isLoggedIn;
    this.loggedInSubscription = this.authService.getLoggedInEvent().subscribe((loggedIn) => {
      this.isUserLoggedIn = loggedIn;
      if (loggedIn) {
        this.sessionService.setupSessionRenewal();
      }
    });
    this.termsAcceptedSubscription = this.termsAndConditionService.getTermsAndConditionAcceptanceEvent().subscribe((accepted) => {
      this.isTermsAccepted = accepted;
    });
    this.googleAnalyticsService.initialize();
  }

  ngOnDestroy(): void {
    this.loggedInSubscription?.unsubscribe();
    this.termsAcceptedSubscription?.unsubscribe();
  }
}
