import { Component } from '@angular/core';
import { FooterComponent, NavbarComponent } from 'adk-portal-shared';

@Component({
  selector: 'app-access-denied',
  standalone: true,
  imports: [NavbarComponent, FooterComponent],
  templateUrl: './access-denied.component.html',
  styleUrl: './access-denied.component.scss'
})
export class AccessDeniedComponent {
}
