import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { UserGroupService } from '../service/user-group.service';

@Directive({
  selector: '[userRoles]',
  standalone: true
})
export class UserRoleDirective implements OnInit {

  @Input() role: string[] | string | undefined;

  constructor(private el: ElementRef, private userGroupService: UserGroupService) {
   }

  ngOnInit(): void {
    const userGroups = this.userGroupService.getUserGroups();
    if (this.role) {
        let allowed = false;
        if(typeof this.role === 'string') this.role = [this.role];
        this.role.forEach(r => {
          if (userGroups.some(group => group === r)) {
            allowed = true;
            return;
          }
        });
        
        if (!allowed) {
          this.el.nativeElement.style.display = 'none';
        }
    }
  }
}
