//TODO - fix lint issue
/* eslint-disable @typescript-eslint/no-unused-vars */
import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from 'adk-portal-shared';
import { Observable, of } from 'rxjs';
import { UserGroups } from '../models/user.model';

export const RoleAccessGuard: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> => {
  const authService = inject(AuthService);
  const router = inject(Router);
  const allowedRoles = route.data['allowedRoles'] as UserGroups[];

  // owners and super admin have access to all the routes
  allowedRoles.push(UserGroups.OWNER);
  allowedRoles.push(UserGroups.SUPER_ADMIN);

  const userGroups = authService.user.cognitoGroups;
  const hasRole = userGroups.some((group) => allowedRoles.includes(group as UserGroups));

  if (!hasRole) {
    router.navigate(['/access-denied']);
  }

  return of(hasRole);
};
